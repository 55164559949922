import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img4 from '../Images/img4.jpeg';
import img5 from '../Images/Img5.jpeg';
import img6 from '../Images/img6.jpeg';
import img7 from '../Images/img7.jpeg';
import img11 from '../Images/Img11.jpeg';
// import img8 from '../Images/img8.jpeg';
import '../CSS/Service.css';

function Service() {

    return (
        <div className='service-page'>
            <Navbar />
            <div className="service-containt">
                <h1>Services</h1>
                <div className='services'>
                    <img src={img4} alt="" />
                    <div>
                        <h2>Redevelopments</h2>
                        <p>
                            Redevelopment of cooperative housing societies has become a crucial aspect of urban development, especially in cities like Mumbai where land is scarce and demand for housing is high. At its core, redevelopment involves the demolition and reconstruction of existing buildings to create newer, more modern structures that better utilize available space and cater to the evolving needs of residents.

                            For cooperative housing societies, redevelopment offers a unique opportunity to upgrade aging infrastructure, improve living conditions, and enhance the overall quality of life for its members. It typically involves collaboration between the society members, developers, architects, and various government authorities to ensure a smooth transition from the old to the new.

                            The process of redevelopment typically begins with the appointment of a competent developer who specializes in such projects. This developer works closely with the society members to understand their needs, preferences, and concerns. Detailed plans are then drawn up, taking into account factors such as building design, amenities, parking facilities, and environmental sustainability.

                            One of the key aspects of redevelopment is ensuring that existing residents are adequately compensated and provided with temporary accommodation during the construction phase. Clear agreements are drafted to protect the rights and interests of all parties involved, including provisions for fair compensation, timelines, and quality standards.

                            Throughout the redevelopment process, effective communication and transparency are paramount to address any issues or concerns that may arise. Regular updates and consultations with society members help build trust and ensure that their voices are heard every step of the way.

                            Once the new construction is complete, residents have the opportunity to move into modern, well-designed homes equipped with state-of-the-art amenities and facilities. The revitalized housing complex not only enhances the quality of life for existing residents but also adds value to the surrounding neighborhood.

                            In conclusion, the redevelopment of cooperative housing societies is a complex yet rewarding endeavor that holds the promise of a brighter future for urban communities. By embracing innovative solutions and fostering collaboration, we can create sustainable, inclusive spaces that enrich the lives of all residents for generations to come.
                        </p>
                    </div>
                    </div>
                </div>
                <div className="service-containt">
                    <div className='services'>
                        <div>
                            <h2>
                                Construction Management
                            </h2>
                            <p>
                                Construction management is the backbone of any successful construction project, and our professional services are tailored to ensure seamless execution from start to finish. With expertise in all areas of construction, including planning, design, pre-construction, and post-construction phases, we are committed to delivering projects that exceed expectations in terms of quality, cost, time, and scope of work.

                                Our comprehensive approach to construction management begins with meticulous planning, where we work closely with clients to understand their vision, requirements, and budget constraints. Through detailed feasibility studies and risk assessments, we identify potential challenges and develop strategies to mitigate them, ensuring a solid foundation for project success.

                                During the design phase, our team of experts collaborates with architects, engineers, and other stakeholders to translate conceptual ideas into practical solutions. By leveraging the latest technologies and industry best practices, we optimize designs for efficiency, sustainability, and compliance with regulatory standards.

                                Pre-construction is a critical phase where thorough preparation is key to laying the groundwork for construction activities. From procurement and subcontractor selection to scheduling and logistics planning, we meticulously plan every aspect of the project to minimize delays, reduce costs, and maintain quality control.

                                Once construction is underway, our dedicated team of project managers and site supervisors ensure that work progresses according to plan, monitoring performance, managing resources, and addressing any issues that may arise in a timely manner. We prioritize safety, quality, and efficiency to deliver projects on schedule and within budget, without compromising on excellence.

                                Post-construction, our commitment to client satisfaction continues as we conduct thorough inspections, address any deficiencies, and ensure a smooth transition to occupancy. We provide comprehensive documentation and support services to facilitate the seamless operation and maintenance of completed facilities.

                                At every stage of the construction process, our focus remains on delivering exceptional results that meet or exceed our clients' expectations. With our professional construction management services, you can trust us to navigate the complexities of your project with precision, integrity, and a relentless dedication to excellence.
                            </p>
                        </div>
                        <img src={img5} alt="" />
                    </div>
                </div>
                <div className="service-containt">
                    <div className='services'>
                        <img src={img6} alt="" />
                        <div>
                            <h2>
                                Project Management
                            </h2>
                            <p>
                                Project management is the cornerstone of successful project delivery, and our dedicated team of project managers is committed to ensuring that every aspect of your project is meticulously planned, coordinated, and controlled from inception to completion. With a focus on meeting our clients' requirements, we strive to produce functional and financially viable projects that are completed on time and within budget.

                                Our project managers bring a wealth of experience and expertise to the table, leveraging industry best practices and innovative solutions to overcome challenges and deliver results. From initial concept development and feasibility studies to final handover and occupancy, we oversee every stage of the project lifecycle with precision and attention to detail.

                                At the heart of our approach to project management is effective communication and collaboration. We work closely with clients, stakeholders, and project teams to ensure that everyone is aligned with project goals and objectives. By fostering open dialogue and transparency, we create a supportive environment where ideas are shared, issues are addressed, and decisions are made with confidence.

                                One of our primary objectives as project managers is to proactively identify and mitigate risks to minimize disruptions and keep the project on track. Through thorough risk assessments and contingency planning, we anticipate potential challenges and develop strategies to address them swiftly and effectively.

                                We understand the importance of delivering projects on time and within budget, and we prioritize efficient resource allocation, scheduling, and cost control to achieve these goals. Our team utilizes advanced project management tools and techniques to track progress, monitor performance, and make data-driven decisions that optimize project outcomes.

                                Ultimately, our mission as project managers is to exceed our clients' expectations by delivering projects that not only meet their requirements but also inspire confidence and trust. With our unwavering commitment to excellence and a client-centered approach, you can rely on us to navigate the complexities of your project with professionalism, integrity, and a relentless pursuit of success.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="service-containt">
                    <div className='services'>
                        
                        <div>
                            <h2>
                                Structural Repairs
                            </h2>
                            <p>
                                Structural integrity is essential for the longevity and safety of any building, and our expertise in structural repair activities ensures that your infrastructure remains strong, resilient, and aesthetically pleasing for years to come. Our comprehensive range of services covers a wide spectrum of repair and restoration needs, including:

                                Rehabilitation, Restoration & Repairs: Whether your structure has suffered from age-related deterioration, environmental factors, or unforeseen damage, our team has the knowledge and skills to rehabilitate, restore, and repair it to its original condition.

                                Corrosion Control: Corrosion can compromise the structural integrity of buildings, especially in environments with high humidity or exposure to corrosive elements. We employ advanced techniques and materials to control corrosion and prevent further damage.

                                Restoration of Heritage Structures: Preserving the historical significance and architectural beauty of heritage structures requires specialized expertise. We have a proven track record in restoring heritage buildings to their former glory while incorporating modern techniques to ensure longevity.

                                Guniting: Guniting is a method used to repair and strengthen concrete structures by spraying a mixture of cement, sand, and water at high velocity. Our skilled technicians utilize guniting techniques to reinforce weakened areas and restore structural stability.

                                External Finishes: The exterior appearance of a building not only enhances its aesthetic appeal but also protects it from environmental elements. We offer a range of external finishes, including plastering, cladding, and facade treatments, to enhance durability and visual appeal.

                                Waterproofing: Proper waterproofing is essential to prevent water infiltration, which can cause damage to structures and compromise their integrity. We specialize in waterproofing solutions for basements, roofs, walls, and other critical areas to ensure long-term protection against moisture ingress.

                                External & Internal Painting: A fresh coat of paint can rejuvenate the appearance of a building while also providing protection against the elements. Our painting services cover both external and internal surfaces, using high-quality materials and skilled craftsmanship to achieve lasting results.

                                Elevational Treatments: Enhancing the elevation of a building can significantly improve its visual impact and curb appeal. We offer a range of elevational treatments, including cladding, facade enhancements, and architectural detailing, to transform the look of your structure.

                                Working with Various Polymer Plasters: Polymer plasters offer superior durability, flexibility, and adhesion compared to traditional plastering materials. Our expertise in working with polymer plasters allows us to deliver high-quality finishes that withstand the test of time.

                                With our commitment to excellence, attention to detail, and use of advanced techniques and materials, you can trust us to address all your structural repair and restoration needs with precision and professionalism.
                            </p>
                        </div>
                        <img src={img7} alt="" />
                    </div>
                </div>

                <div className="service-containt">
                    <div className='services'>
                        
                        <div>
                            <h2>
                            Building Convene
                            </h2>
                            <p>
                            A Building Convene Document is an essential part of the redevelopment process. It formalizes the decision of a building's residents or society members to move forward with redevelopment by officially selecting a developer and approving the project plan
                            </p>
                        </div>
                        <img src={img11} alt="" />
                    </div>
                </div>
            <Footer />
        </div>
    )
}

export default Service
